import React from 'react';
import './Timeline.css'; // Erstellen Sie eine entsprechende CSS-Datei für Stile

const Timeline = ({ years, color }) => {
    return (
      <div className="timeline-container">
        {years.map((year, index) => (
          <div key={year + "-" + index} className="timeline-year" style={{borderColor: color}}>
            {year}
          </div>
        ))}
      </div>
    );
  };

export default Timeline;