import React, { Component } from "react";
import * as $ from "jquery";
import { authEndpoint, clientId, redirectUri, scopes } from "./config";
import hash from "./hash";
import Player from "./Player";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faAngleRight,
  faForwardStep,
  faHandPointUp,
  faGear,
  faCoins,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import Timeline from "./Timeline";
import logo from "./assets/Logo2.png";

class App extends Component {
  constructor() {
    super();
    this.state = {
      songID: "",
      newSongID: "",
      token: null,
      item: {
        album: {
          images: [{ url: "" }],
        },
        name: "",
        artists: [{ name: "" }],
        duration_ms: 0,
      },
      is_playing: false, // Verwende einen boolean-Wert für den Wiedergabestatus
      progress_ms: 0,
      no_data: false,
      yearInput: "",
      interfereYearInput: "",
      blurEffect: true,
      yearsTeam1: [],
      yearsTeam2: [],
      chipsTeam1: 0,
      chipsTeam2: 0,
      showNextSongButton: false,
      discogsYear: 9999,
      selectedYear: null,
      autoStop: false,
      isInterfere: false,
      interfereShake: false,
      interfereColor: "white",
      showInterfere: true,
      showChip: false,
      isSettingsOpen: false,
    };

    this.getCurrentlyPlaying = this.getCurrentlyPlaying.bind(this);
    this.tick = this.tick.bind(this);
    this.handleYearInputChange = this.handleYearInputChange.bind(this);
    this.handleInterfereInputChange =
      this.handleInterfereInputChange.bind(this);
    this.handleConfirmYear = this.handleConfirmYear.bind(this);
    this.toggleSettings = this.toggleSettings.bind(this);
  }

  componentDidMount() {
    // Set token
    let _token = hash.access_token;

    if (_token) {
      // Set token
      this.setState({
        token: _token,
      });
      this.getCurrentlyPlaying(_token);
    }

    // set interval for polling every 1 second
    this.interval = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    // clear the interval to save resources
    clearInterval(this.interval);
  }

  tick() {
    if (this.state.token) {
      this.getCurrentlyPlaying(this.state.token);
      this.getDiscogsReleaseDate();
      if (this.state.autoStop) {
        let progess = this.state.progress_ms / this.state.item.duration_ms;
        if (progess > 0.95 && this.state.is_playing.valueOf("pause")) {
          this.togglePlayback(this.state.token);
        }
      }
    }
  }

  getDiscogsReleaseDate() {
    if (this.state.songID !== this.state.newSongID) {
      this.setState({ newSongID: this.state.songID });

      const title = this.state.item.name.split("-")[0].trim();
      const artist = this.state.item.artists[0].name;

      $.ajax({
        url: `https://api.discogs.com/database/search?release_title=${title}&artist=${artist}&token=BOqfgRAZPgoEiTMBRjtxOyCVYlqoDQWHtZXTysMS`,
        type: "GET",
        success: (data) => {
          // Checks if the data is not empty
          if (!data) {
            this.setState({
              no_data: true,
            });
            return;
          }

          let results = data.results;
          let earliestRelease = 9999;

          results.forEach(function (result) {
            let year = result.year;
            if (year !== undefined && year < earliestRelease) {
              earliestRelease = year;
            }
          });

          this.setState({ discogsYear: earliestRelease });
        },
      });
    }
  }

  getCurrentlyPlaying(token) {
    // Make a call using the token
    $.ajax({
      url: "https://api.spotify.com/v1/me/player",
      type: "GET",
      beforeSend: (xhr) => {
        xhr.setRequestHeader("Authorization", "Bearer " + token);
      },
      success: (data) => {
        // Checks if the data is not empty
        if (!data) {
          this.setState({
            no_data: true,
          });
          return;
        }

        //console.log(data);
        this.setState({
          item: data.item,
          is_playing: data.is_playing,
          progress_ms: data.progress_ms,
          no_data: false,
          songID: data.item.id,
        });
      },
    });
  }

  togglePlayback(token) {
    const { is_playing } = this.state;
    const action = is_playing ? "pause" : "play"; // Bestimme die Aktion basierend auf dem aktuellen Status

    $.ajax({
      url: `https://api.spotify.com/v1/me/player/${action}`,
      type: "PUT",
      beforeSend: (xhr) => {
        xhr.setRequestHeader("Authorization", "Bearer " + token);
      },
      success: () => {
        //console.log(action);
        this.setState({
          is_playing: !is_playing, // Aktualisiere den Wiedergabestatus umgekehrt zum vorherigen Status
        });
      },
      error: (xhr, status, error) => {
        console.error(status, error);
      },
    });
  }

  getNextSong(token) {
    $.ajax({
      url: "https://api.spotify.com/v1/me/player/next",
      type: "POST",
      beforeSend: (xhr) => {
        xhr.setRequestHeader("Authorization", "Bearer " + token);
      },
      success: () => {
        //console.log("Next Song");
      },
      error: (xhr, status, error) => {
        console.error(status, error);
      },
    });

    let otherTeam = this.state.team === 1 ? 2 : 1;

    this.setState({
      blurEffect: true,
      yearInput: "",
      showNextSongButton: false,
      team: otherTeam,
      interfereColor: "white",
      selectedYear: null,
      showChip: false,
      showInterfere: true,
    });
  }

  handleYearInputChange(event) {
    this.setState({ yearInput: event.target.value, interfereShake: true });
    if (event.target.value === "") {
      this.setState({ interfereShake: false });
    }
  }

  handleInterfereInputChange(event) {
    this.setState({ interfereYearInput: event.target.value });
  }

  handleConfirmYear() {
    if (this.state.isInterfere === false) {
      this.setState({ interfereShake: false, showInterfere: false });
      const yearFromInput = parseInt(this.state.yearInput);
      let yearFromReleaseDate = null;
      if (this.state.selectedYear != null) {
        yearFromReleaseDate = this.state.selectedYear;
      } else {
        this.setState({
          blurEffect: false,
        });
        return;
      }

      let { yearsTeam1, yearsTeam2, team } = this.state;

      let years;

      if (team === 1) {
        years = this.modifyTimeline(
          yearsTeam1,
          yearFromInput,
          yearFromReleaseDate
        );
        this.setState({
          blurEffect: false,
          yearsTeam1: years,
          showNextSongButton: true,
          selectedYear: null,
        });
        if (years.length !== yearsTeam1.length) {
          this.setState({
            showChip: true,
          });
        }
      } else {
        years = this.modifyTimeline(
          yearsTeam2,
          yearFromInput,
          yearFromReleaseDate
        );
        this.setState({
          blurEffect: false,
          yearsTeam2: years,
          showNextSongButton: true,
          selectedYear: null,
        });
        if (years.length !== yearsTeam1.length) {
          this.setState({
            showChip: true,
          });
        }
      }
    } else {
      const yearFromInput = parseInt(this.state.yearInput);
      const interfereYearFromInput = parseInt(this.state.interfereYearInput);

      let yearFromReleaseDate = null;
      if (this.state.selectedYear != null) {
        yearFromReleaseDate = this.state.selectedYear;
      } else {
        this.setState({
          blurEffect: false,
        });
        return;
      }

      this.setState({ interfereShake: false });
      let { yearsTeam1, yearsTeam2, team } = this.state;

      let years;

      if (team === 1) {
        years = this.modifyTimeline(
          yearsTeam1,
          yearFromInput,
          yearFromReleaseDate
        );

        if (years.length !== yearsTeam1.length) {
          this.setState({
            blurEffect: false,
            yearsTeam1: years,
            showNextSongButton: true,
            selectedYear: null,
            showChip: true,
          });
        } else {
          years = this.modifyTimeline(
            yearsTeam1,
            interfereYearFromInput,
            yearFromReleaseDate
          );

          this.setState({
            blurEffect: false,
            yearsTeam2: [...yearsTeam2, yearFromReleaseDate].sort(
              (a, b) => a - b
            ),
            showNextSongButton: true,
            selectedYear: null,
          });
        }
      } else {
        years = this.modifyTimeline(
          yearsTeam2,
          yearFromInput,
          yearFromReleaseDate
        );

        if (years.length !== yearsTeam2.length) {
          this.setState({
            blurEffect: false,
            yearsTeam2: years,
            showNextSongButton: true,
            selectedYear: null,
            showChip: true,
          });
        } else {
          years = this.modifyTimeline(
            yearsTeam2,
            interfereYearFromInput,
            yearFromReleaseDate
          );

          this.setState({
            blurEffect: false,
            yearsTeam1: [...yearsTeam1, yearFromReleaseDate].sort(
              (a, b) => a - b
            ),
            showNextSongButton: true,
            selectedYear: null,
          });
        }
      }
      this.setState({ isInterfere: false, interfereYearInput: "" });
    }
  }

  modifyTimeline(years, yearFromInput, yearFromReleaseDate) {
    years = [...years, 0, 9999].sort((a, b) => a - b);

    years = years.sort((a, b) => a - b);

    let prevYear = 0;
    let nextYear = 9999;

    years.forEach(function (year) {
      if (year > prevYear && year < yearFromReleaseDate) {
        prevYear = year;
      }

      if (year < nextYear && year > yearFromReleaseDate) {
        nextYear = year;
      }
    });

    if (yearFromInput >= prevYear && yearFromInput <= nextYear) {
      years = [...years, yearFromReleaseDate].sort((a, b) => a - b);
    }
    years.shift();
    years.pop();

    return years;
  }

  getYearFromReleaseDate(releaseDate) {
    if (releaseDate && releaseDate.length >= 4) {
      return releaseDate.split("-")[0];
    }
    return "Unknown";
  }

  setSelectedYear = (year) => {
    this.setState({ selectedYear: year });
  };

  interfere(team) {
    if (this.state.interfereShake) {
      this.setState({ interfereShake: false, isInterfere: true });
      console.log(this.state.yearInput);
      if (team === 1) {
        this.setState({ interfereColor: "#420000" });
        this.setState((prevState) => ({
          chipsTeam1: prevState.chipsTeam1 - 1,
        }));
      } else {
        this.setState({ interfereColor: "#000042" });
        this.setState((prevState) => ({
          chipsTeam2: prevState.chipsTeam2 - 1,
        }));
      }
    }
  }

  addChip(team) {
    if (team === 1) {
      this.setState((prevState) => ({
        chipsTeam1: prevState.chipsTeam1 + 1,
      }));
    } else {
      this.setState((prevState) => ({
        chipsTeam2: prevState.chipsTeam2 + 1,
      }));
    }
    this.setState({ showChip: false });
  }

  toggleSettings = () => {
    this.setState((prevState) => ({
      isSettingsOpen: !prevState.isSettingsOpen,
    }));
  };

  handleAutoStopChange = (event) => {
    this.setState({ autoStop: event.target.checked });
  };

  render() {
    return (
      <div className="App">
        <header className="App-header">
          {!this.state.token && (
            <div className="start-page">
              <img src={logo} alt="Hitster Online" className="main-logo"></img>
              <div className="main-title">
                <h3>TaktTipp</h3>
                <span className="main-subtitle">a hitster clone</span>
              </div>
              <a
                className="btn btn--loginApp-link"
                href={`${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
                  "%20"
                )}&response_type=token&show_dialog=true`}
              >
                Start PLAYING
              </a>
            </div>
          )}
          {this.state.token && !this.state.no_data && (
            <div>
              <div className="header">
                <div className="header-left">
                  <img src={logo} alt="Hitster Online" className="game-logo" />
                  <div className="title-container">
                    <h3>TaktTipp</h3>
                    <span className="subtitle">a hitster clone</span>
                  </div>
                </div>
                <div className="header-right">
                  <FontAwesomeIcon
                    className="settings"
                    icon={this.state.isSettingsOpen ? faXmark : faGear}
                    color="white"
                    size="1x"
                    onClick={this.toggleSettings}
                  />
                </div>
              </div>
              {this.state.isSettingsOpen && (
                <div
                  className={`settings-menu ${
                    this.state.isSettingsOpen ? "open" : ""
                  }`}
                >
                  <div className="settings-option">
                    <label htmlFor="auto-stop-toggle">
                      Stop music automatically when 95% of the song is played
                    </label>
                    <div className="checkbox-wrapper-44">
                      <label className="toggleButton">
                        <input type="checkbox" checked={this.state.autoStop} onChange={this.handleAutoStopChange}/>
                        <div>
                          <svg viewBox="0 0 44 44">
                            <path
                              d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758"
                              transform="translate(-2.000000, -2.000000)"
                            ></path>
                          </svg>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              )}
              <div className="game">
                <div className="player">
                  <Player
                    item={this.state.item}
                    is_playing={this.state.is_playing}
                    progress_ms={this.state.progress_ms}
                    blurEffect={this.state.blurEffect}
                    getYearFromReleaseDate={this.getYearFromReleaseDate}
                    discogsYear={this.state.discogsYear}
                    selectedYear={this.state.selectedYear}
                    setSelectedYear={this.setSelectedYear}
                  />
                  <div className="button-wrapper">
                    <FontAwesomeIcon
                      className="play-icon"
                      icon={this.state.is_playing ? faPause : faPlay}
                      color="white"
                      size="lg"
                      onClick={() => this.togglePlayback(this.state.token)}
                    />
                    {this.state.showNextSongButton && (
                      <FontAwesomeIcon
                        className="forward-icon"
                        icon={faForwardStep}
                        color="white"
                        size="lg"
                        onClick={() => this.getNextSong(this.state.token)}
                      />
                    )}
                  </div>
                  <div className="input-wrapper">
                    <input
                      type="text"
                      value={
                        this.state.isInterfere
                          ? this.state.interfereYearInput
                          : this.state.yearInput
                      }
                      onChange={
                        this.state.isInterfere
                          ? this.handleInterfereInputChange
                          : this.handleYearInputChange
                      }
                      placeholder={
                        this.state.isInterfere
                          ? "interfere"
                          : "enter release date"
                      }
                    />
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      onClick={this.handleConfirmYear}
                    />
                  </div>
                  {this.state.team === 1 &&
                  this.state.chipsTeam2 > 0 &&
                  this.state.showInterfere ? (
                    <FontAwesomeIcon
                      icon={faHandPointUp}
                      onClick={() => this.interfere(2)}
                      className={
                        this.state.interfereShake
                          ? "shake-interfere interfere"
                          : "interfere"
                      }
                      style={{ color: this.state.interfereColor }}
                    />
                  ) : this.state.team === 2 &&
                    this.state.chipsTeam1 > 0 &&
                    this.state.showInterfere ? (
                    <FontAwesomeIcon
                      icon={faHandPointUp}
                      onClick={() => this.interfere(1)}
                      className={
                        this.state.interfereShake
                          ? "shake-interfere interfere"
                          : "interfere"
                      }
                      style={{ color: this.state.interfereColor }}
                    />
                  ) : null}
                </div>
                <div className="timeline">
                  {this.state.team === 1 ? (
                    <Timeline years={this.state.yearsTeam1} color="#420000" />
                  ) : (
                    <Timeline years={this.state.yearsTeam2} color="#000042" />
                  )}
                </div>
                {this.state.showChip ? (
                  <FontAwesomeIcon
                    icon={faCoins}
                    style={{
                      color: this.state.team !== 1 ? "#000042" : "#420000",
                    }}
                    onClick={() =>
                      this.state.team === 1 ? this.addChip(1) : this.addChip(2)
                    }
                  />
                ) : null}
              </div>
            </div>
          )}
          {this.state.no_data && (
            <p>Play music with your connected Spotify account.</p>
          )}
        </header>
      </div>
    );
  }
}

export default App;
