import React, { useState, useEffect  } from "react";
import "./Player.css";

const Player = (props) => {


  const backgroundStyles = {
    backgroundImage: `url(${props.item.album.images[0].url})`,
  };

  const year = props.getYearFromReleaseDate(props.item.album.release_date);

  const discogsYear = props.discogsYear;

  const progressBarStyles = {
    width: (props.progress_ms * 100) / props.item.duration_ms + "%",
  };

  const blurStyle = props.blurEffect ? { filter: "blur(50px)" } : {};

  const [selectedYear, setSelectedYear] = useState(null);

  const onSelectYear = (year) => {
    setSelectedYear(year)
    props.setSelectedYear(year);
  };

  const yearStyle =
    selectedYear === year ? { color: "white" } : { color: "grey" };
  const discogsYearStyle =
    selectedYear === discogsYear ? { color: "white" } : { color: "grey" };

    useEffect(() => {
      const timer = setTimeout(() => {
        if (discogsYear === 9999 || Math.abs(discogsYear - year) < 2) {
          setSelectedYear(year);
          props.setSelectedYear(year);
        }
      }, 1500); 
  
      return () => clearTimeout(timer); 
    }, [year, discogsYear, props.item.name]);

  return (
    <div className="App">
      <div className="main-wrapper">
        <div className="now-playing__img" style={blurStyle}>
          <img src={props.item.album.images[0].url} alt="album cover" />
        </div>
        <div className="now-playing__side">
          <div className="now-playing__name" style={blurStyle}>{props.item.name}</div>
          <div className="now-playing__artist" style={blurStyle}>
            {props.item.artists[0].name}
          </div>
          <div className="now-playing__year" style={blurStyle}>
            <span style={yearStyle} onClick={() => onSelectYear(year)}>
              {year}
            </span>
            {Math.abs(discogsYear - year) >= 2 && discogsYear !== 9999 && (
              <span>
                {" | "}
                <span
                  style={discogsYearStyle}
                  onClick={() => onSelectYear(discogsYear)}
                >
                  {discogsYear}
                </span>
              </span>
            )}
          </div>
          <div className="progress">
            <div className="progress__bar" style={progressBarStyles} />
          </div>
        </div>
        <div className="background" style={backgroundStyles} />{" "}
      </div>
    </div>
  );
};

export default Player;
